<template>
    <div>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card header="Entry Tags">
                    <b-skeleton width="100%"></b-skeleton>
                </b-card>
            </template>

            <div class="no-tags" v-if="!showTags">
                <b-alert variant="info" show>
                    Tags allow you to categorise and group your Diary and Reflection entries.
                </b-alert>
                <a href="#" @click="showAddTags">+ Add your first tag</a>
            </div>
            <div class="has-tags mt-2" v-if="showTags">
                <b-form-tags placeholder="Add Tag..." v-model="tags">


                    <template v-slot="{ inputId, tags }">
                        <b-input-group>
                            <b-input maxlength="50" ref="tagInput" v-model="newTag" :id="inputId" placeholder="Add tag..."
                                @input="handleTagInput" @keydown.enter="addTag(newTag)" />
                            <b-input-group-append>
                                <b-button @click="addTag(newTag)" :disabled="isTagEmpty" class="ovr-button__inverted"
                                    variant="primary">Add</b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <small>Characters remaining: {{ remainingCharacters }}</small>
                        <div v-if="tags.length" class="profile-tags" style="font-size: 1.5rem;">
                            <b-form-tag v-for="tag in tags" @remove="removeTag(tag)" :key="tag" :title="tag"
                                class="profile-tag mr-1 mb-1">{{
                                    tag }}</b-form-tag>
                        </div>
                    </template>


                </b-form-tags>
            </div>
        </b-skeleton-wrapper>
    </div>
</template>

<script>
import Providers from '@/providers';

export default {
    name: "ProfileEntryTags",
    components: {},
    data() {
        return {
            tags: [],
            showTagsOverride: false,
            loading: false,
            remainingCharacters: 50,
            isTagEmpty: true,

            newTag: ''
        }
    },
    computed: {
        showTags() {
            return this.tags.length > 0 || this.showTagsOverride;
        }
    },
    methods: {
        resetTag() {
            this.remainingCharacters = 50
            this.newTag = ''
        },
        handleTagInput(string) {
            this.isTagEmpty = !(!!string.trim().length)

            this.remainingCharacters = 50 - string.length
        },
        showAddTags() {
            this.showTagsOverride = true;
        },
        async addTag(tag) {
            this.tags.push(tag);

            this.resetTag()

            if (this.isTagEmpty) return

            this.save(true, true)
        },
        removeTag(index) {
            this.tags.splice(index, 1);

            this.save(true, false)
        },
        save(shouldHidePreviousToast, shouldFocusOnTagInput) {
            this.loading = true;
            Providers.profile.updateTags(this.tags).then(() => {
                this.$success('Tags Saved', 'Your tags have been saved successfully.');
            }).catch((resp) => {
                this.$httpError('Failed to save tags', resp);
            }).finally(() => {
                this.loading = false;

                if (shouldHidePreviousToast) {
                    this.$hideToast()
                    if (shouldFocusOnTagInput) {
                        this.$nextTick(() => {
                            this.$refs.tagInput.focus()
                            this.isTagEmpty = true
                        });
                    }
                }
            });
        },
        loadTags() {
            this.loading = true;
            Providers.profile.tags().then((tags) => {
                this.tags = tags;
            }).catch((err) => {
                this.$httpError('Failed to load tags', err);
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    created() {
        this.loadTags();
    }
}
</script>

<style lang="scss" scoped>
.tags {
    display: block;
}

.profile-tags {
    display: flex;
    flex-wrap: wrap;
}

.profile-tag {
    background: $border-color;
}
</style>
