import store from '../store';
import Vue from 'vue';
import router from "../router";

export default {
    login(email, password) {
        return Vue.http.post('auth/login', {email, password, temporary: true}).then(response => {
            if (response.status === 200) {
                let token = response.body.token;
                let admin = response.body.roles.indexOf('Admin') > -1;
                return store.dispatch('authentication/storeLoginDetails', {
                    token: token,
                    isAdmin: admin,
                    roles: response.body.roles
                }).then(() => {
                    return true;
                });
            }
        }).catch((resp) => {
            throw resp.body.message;
        })
    },
    loginOrganisationUser(emailAddress) {
        return Vue.http.post(`organisations/user-review/${emailAddress}/access-cpd-record`).then((resp) => {
            let tokenResponse = resp.body;
            return store.dispatch('authentication/storeOverrideLoginDetails', {
                token: tokenResponse.token,
                roles: tokenResponse.roles
            });
        }).then(() => {
            let loginAsUrl = router.resolve({name: 'login-override'});
            window.open(loginAsUrl.href, '_blank');
        }).catch((resp) => {
            this.$httpError('Failed to login', resp);
        });
    },
    configureForOverrideSession() {
        if (store.state.authentication.override) {
            return store.dispatch('session/setOverride', {override: true}).then(() => {
                Vue.http.headers.common['Authorization'] = 'Bearer ' + store.state.authentication.override.loginToken;
            });
        }
        return false;
    },
    roles() {
        if (store.getters["session/useOverride"]) {
            return store.getters["authentication/getOverrideRoles"];
        }
        return store.getters["authentication/getRoles"];
    },
    loginToken() {
        if (store.getters["session/useOverride"]) {
            return store.getters["authentication/getOverrideToken"];
        }
        return store.getters["authentication/getToken"];
    }
}