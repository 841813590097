<template>
	<div>
		<p ref="textContainer" v-html="formattedString"></p>
		<div class="read-more__footer">
			<span v-if="date" class="read-more__section-date">
				<clock-outline-icon /> {{ formattedTime }}
			</span>
			<div class="mb-2">
				<slot name="before-buttons" />
			</div>
			<span>
				<b-button variant="outline-dark" size="sm" v-show="!isReadMore && text.length > maxChars" @click="triggerReadMore($event, true)">{{
					moreStr }}</b-button>
				<b-button variant="outline-dark" size="sm" v-show="isReadMore && text.length > maxChars" @click="triggerReadMore($event, false)">{{
					lessStr }}</b-button>
				<b-button :variant="buttonVariant" size="sm"
					v-if="this.actionTitle" @click="this.actionCallback" :disabled="actionDisabled"
					v-requires-role="'User-Basic'">{{ this.actionTitle }}</b-button>
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ReadMore',
	props: {
		date: {
			type: Date,
			default: null
		},
		actionTitle: {
			type: String,
			default: null
		},
		actionCallback: {
			type: Function,
			default: () => {

			}
		},
		actionDisabled: {
			type: Boolean,
			default: false
		},
		moreStr: {
			type: String,
			default: 'See more...'
		},
		lessStr: {
			type: String,
			default: 'Show less'
		},
		text: {
			type: String,
			required: true
		},
		link: {
			type: String,
			default: '#'
		},
		maxChars: {
			type: Number,
			default: 100
		},
		buttonVariant: {
			type: String,
			default: 'info'
		},
	},

	data() {
		return {
			isReadMore: false,
			formattedString: '',
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.calculateCharacterCount();
			this.formattedString = this.getFormattedString();
		});
	},
	watch: {
		isReadMore() {
			this.getText()
		},
		text() {
			this.getText()
		},
	},
	methods: {
		getText() {
			this.calculateCharacterCount();
			this.formattedString = this.getFormattedString();
		},
		getFormattedString() {
			let valContainer = this.text;
			const maxChars = this.calculateCharacterCount() || this.maxChars;

			if (!this.isReadMore && this.text.length > maxChars) {
				valContainer = valContainer.substring(0, maxChars) + '...';
			}

			return valContainer;
		},
		calculateCharacterCount() {
			const container = this.$refs.textContainer;
			if (container) {
				const containerWidth = container.clientWidth;

				const tempDiv = document.createElement('div');
				tempDiv.style.fontSize = window.getComputedStyle(container).fontSize;
				tempDiv.style.lineHeight = window.getComputedStyle(container).lineHeight;
				document.body.appendChild(tempDiv);

				const fontSize = parseInt(window.getComputedStyle(tempDiv).fontSize, 10);
				document.body.removeChild(tempDiv);

				const charsPerLine = Math.floor(containerWidth / fontSize);
				const maxChars = charsPerLine * 5;

				return maxChars
			}
		},
		triggerReadMore(e, b){
			if(this.lessStr !== null || this.lessStr !== '')
				this.isReadMore = b;
		}
	},
	computed: {
		formattedTime() {
			const dateString = this.date;
			const dateObj = new Date(dateString);

			const day = dateObj.getDate().toString().padStart(2, '0');
			const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
			const year = dateObj.getFullYear().toString();
			const hours = dateObj.getHours();
			const minutes = dateObj.getMinutes().toString().padStart(2, '0');
			const period = hours >= 12 ? 'pm' : 'am';

			const formattedDate = `${day}. ${month}. ${year}`;
			const formattedTime = `${(hours % 12 || 12).toString().padStart(2, '0')}:${minutes}${period}`;

			return `${formattedDate}   ${formattedTime}`;
		}
	},
}
</script>

<style scoped lang="scss">
.btn {
	margin-right: 10px;
}

.read-more__footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}
.read-more__section-date {
	color: rgb(103, 143, 163);
	font-size: 14px;
	font-weight: 500;
	white-space: pre;
	display: flex;
	align-items: center;

	.material-design-icon {
		svg {
			bottom: 0;
		}
	}
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
	.read-more__section-date {
		margin-bottom: 8px;
	}
}
</style>
