<template>
    <div class="share-cpd-modal">

        <b-modal class="cpd-modal" scrollable title="Share your CPD" ref="modal" size="lg" okTitle="Share"
                 okVariant="info" :okDisabled="!formState" header-bg-variant="info" header-text-variant="light"
                 @ok="share" no-close-on-backdrop no-close-on-esc>

            <p>Would you like to share this CPD with other CPDme users? Here's a preview of what other users will be able to see:</p>

            <SharedCPDListItem :title="sharedCpd.title"
                               :description="sharedCpd.description"
                               :avatar-url="profileImageUrl"
                               :nickname="nickname"
                               :category="sharedCpd.category"
                               :description-as-readmore="false"
                               :website-url="sharedCpd.websiteUrl"
                               date="Unpublished"/>

            <SharedCPDEditor :shared-cpd="sharedCpd" />

            <b-checkbox v-model="agree" class="mt-4 confidential">I agree that the above does not contain any sensitive information, and I agree to share it with other users of the CPDme platform.</b-checkbox>

        </b-modal>

    </div>
</template>

<script>
    import SharedCPDListItem from "./SharedCPDListItem";
    import SharedCPDEditor from "@/components/shared-cpd/SharedCPDEditor";
    export default {
        name: "ShareCPDModal",
        components: {SharedCPDEditor, SharedCPDListItem},
        props: {
            shareCallback: {
                type: Function,
                required: true
            }
        },
        data: function() {
            return {
                agree: false,
                entryId: null,
                nickname: 'Loading...',
                categories: [],
                sharedCpd: {
                    title: '',
                    description: '',
                    category: '',
                    websiteUrl: ''
                }
            }
        },
        methods: {
            reset() {
                this.agree = false;
            },
            show(diaryEntry) {
                this.reset();
                this.entryId = diaryEntry.id;
                this.sharedCpd.title = diaryEntry.content.Field222;
                this.sharedCpd.category = diaryEntry.content.Field116;
                this.sharedCpd.description = diaryEntry.content.Field113 ? diaryEntry.content.Field113 : '';
                this.sharedCpd.websiteUrl = diaryEntry.content.Field219;
                if (this.sharedCpd.category && !this.categories.includes(this.sharedCpd.category)) {
                    this.categories.push(this.sharedCpd.category);
                }
                this.categories.sort();
                this.$refs.modal.show();
            },
            share() {
                this.shareCallback(this.sharedCpd, this.entryId);
            },
        },
        computed: {
            profileImageUrl() {
                let baseUrl = process.env.VUE_APP_API_ROOT;
                let authToken = this.$store.state.authentication.loginToken;
                return `${baseUrl}profile/image?auth_token=${authToken}`
            },

            formState() {
                return this.agree;
            }
        },
        created() {
            this.$http.get('profile').then((resp) => {
                if (resp.body.nickname) {
                    this.nickname = resp.body.nickname;
                } else {
                    this.nickname = resp.body.firstName;
                }
            })
        }
    }
</script>

<style scoped>
.confidential {
    font-size: 12px;
}

    .confidential label.custom-control-label {
        padding-top: 5px !important;
    }
</style>
